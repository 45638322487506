<template>
  <router-view></router-view>
</template>
<script>
export default {
  name:"temp",
  data(){
    return {
       
    }
  },
}
</script>
<style scoped lang='less'>
   
</style>